import React, { useState, useEffect, useReducer } from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'

import { TeamQuery } from '../../graphql-types'
import { Content } from '../components/styled'
import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from '../Utils'

const BlogIndex = ({
  data,
  location,
}: {
  data: TeamQuery
  location: Location
}) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const edges = data.allContentfulPerson.edges

  return (
    <Layout location={location}>
      <Helmet title={siteTitle} />
      <Styles>
        <Content style={{ background: '#fff' }}>
          <div></div>
          <div>
            <div
              className="intro-text"
              dangerouslySetInnerHTML={{
                __html:
                  data.allContentfulTeamPage.edges[0].node
                    .childContentfulTeamPageIntroductionTextNode
                    .childMarkdownRemark.html,
              }}
            ></div>
            <br />
            <br />
            <MasonryWrapper>
              <Masonry
                enableResizableChildren={true}
                className={'my-gallery-class'}
                elementType={'div'}
                options={{
                  transitionDuration: 250,
                  horizontalOrder: true,
                }}
                disableImagesLoaded={false}
                updateOnEachImageLoad={true}
              >
                {edges
                  .sort((a, b) => {
                    if (
                      typeof a.node.priority !== 'number' ||
                      typeof b.node.priority !== 'number'
                    ) {
                      return -1
                    }

                    console.log(a, b)
                    return a.node.priority > b.node.priority ? 1 : -1
                  })
                  .map(({ node }) => (
                    <Link className="thumbnail" to={node.slug}>
                      <Img
                        key={node.slug + node.name}
                        fluid={node.image.fluid}
                      />
                      <p style={{ margin: '0.2rem 0 0rem 0' }}>{node.name}</p>
                    </Link>
                  ))}
              </Masonry>
            </MasonryWrapper>
          </div>
        </Content>
      </Styles>
    </Layout>
  )
}

const MasonryWrapper = styled.div`
  position: relative;
  width: 100%;

  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;

  * {
    box-sizing: border-box;
  }
`

const Styles = styled.div`
  .intro-text *:first-of-type {
    margin-top: 0;
  }

  .thumbnail {
    width: 33.333%;
    padding: 0.5rem 0.5rem 1.6rem;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .thumbnail {
      width: 100%;
      padding: 0.5rem 2rem 1.6rem 2rem;
    }
  }
`

export default BlogIndex

export const pageQuery = graphql`
  query Team {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTeamPage {
      edges {
        node {
          childContentfulTeamPageIntroductionTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson {
      edges {
        node {
          priority
          slug
          name
          bio {
            bio
          }
          title
          image {
            fluid(maxWidth: 800, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
